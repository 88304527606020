<template>
  <Layout>
    <div>
      <div class="container-fluid pt-5">
        <div class="row">
          <div class="offset-md-3 col-md-6">
            <div class="confirmation-container text-center">
              <div class="logo locked-image"></div>
              <div class="for-applying">Activation key has expired.</div>
              <div class="mt-3 d-grid gap-2">
                <button class="submit">
                  <RouterLink to="/">
                    Back
                  </RouterLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 150px"></div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";

export default {
  name: "key-expired",
  components: {
    Layout
  }
}
</script>

<style scoped>

</style>